@import '../../scss/variables';

.category-section {
    margin-bottom: $spacing-normal;
}

.category-label {
    margin-right: $spacing-small;
    input, select {
        margin-right: $spacing-xs;
        margin-bottom: $spacing-tiny;
    }
}

.category-toggle-button {
    margin-right: $spacing-small;
}