@import '../scss/variables';
@import '../scss/mixins';

.detailClass {
  padding: $spacing-small 0;
}

.jsonInput {
  width: 40rem;
  height: 40rem;
}

.settlementDetails {
  display: grid;
  column-gap: $spacing-small;
  grid-template-columns: auto auto;
}

.coverImgContainer {
  object-fit: contain;
}

.coverImg {
  max-width: 100%;
  max-height: 600px;
  vertical-align: bottom;
}

.spacer {
  margin-bottom: $spacing-small;
  margin-right: $spacing-small;
}

.autoInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid $spleis-blue;

  .autoButtons {
    display: flex;
    margin: $spacing-tiny;
    gap: $spacing-tiny;
  }
}

.amlStatusOk {
  color: green;
}

.amlStatusDeviation {
  color: orange;
}

.amlStatusNotApproved {
  color: red;
}