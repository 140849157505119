@import '../scss/variables';

.timeline {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    width: 100%;
}

.timelineEvent {
    align-items: center;
    color: $oslofjorden-blue;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    padding: 0 $spacing-tiny-small $spacing-tiny-small;

    &::before {
        background-color: $oslofjorden-blue;
        content: '';
        height: 2px;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .circle {
        align-self: center;
        background-color: $oslofjorden-blue;
        border-radius: 50%;
        height: 16px;
        margin: -8px auto $spacing-tiny-small;
        text-align: center;
        z-index: 1;
        width: 16px;
    }

    &.warning {
        .status {
            background-color: $havnelageret-peach;
        }
    }

    &.ongoing {
        color: $t-bane-grey;

        .circle {
            background-color: $jernbanetorget-grey;
        }

        .status {
            background-color: transparent;
        }
    }
}

.timestamp {
    align-items: center;
    display: flex;
    font-size: $font-size-small;
    flex-direction: column;
    margin-bottom: $spacing-tiny-small;
    padding: 0;
}

.status {
    background-color: $sofienbergparken-green;
    border-radius: $border-radius;
    color: $barcode-black;
    display: flex;
    font-size: $font-size-small;
    line-height: 1.2;
    justify-content: center;
    margin: auto;
    max-width: 120px;
    padding: $spacing-tiny-small;
    position: relative;
    text-align: center;
}
